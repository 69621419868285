import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./NavBar.scss";

const Popup = styled.div.attrs({ className: "popup-mainmenu" })`
  position: fixed;
  overflow-x: hidden;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 99;

  @media (max-width: 600px) {
    animation: slideInVertical 0.5s ease-in-out;
  }

  @media (min-width: 601px) {
    animation: slideInHorizontal 0.5s ease-in-out;
  }
`;

const Overlay = styled.div.attrs({ className: "overlay" })``;

const PopupMenu = ({ marginTop, togglePopup, isAuthenticated, onLogout, email }) => {
  return (
    <>
      <Overlay className="active" onClick={togglePopup} />
      <Popup style={{ marginTop: marginTop }}>
        <ul className="popup-menu-container">
          {isAuthenticated ? (
            <>
              {/* <li className="pop_link-btn">
                <p className="pop-up-text">{email || "User Email"}</p>
              </li> */}
              <li className="pop_link-btn" onClick={onLogout}>
                <p className="pop-up-text">Log Out</p>
              </li>
            </>
          ) : (
            <>
              <li className="pop_link-btn" onClick={togglePopup}>
                <Link to="/auth">
                  <p className="pop-up-text">Log In</p>
                </Link>
              </li>
            </>
          )}

          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/">
              <p className="pop-up-text mv1">Home</p>
            </Link>
          </li>

          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/our_impact">
              <p className="pop-up-text mv1">Our Impact</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/our_projects">
              <p className="pop-up-text mv1">Our Projects</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/support">
              <p className="pop-up-text mv1">FAQs</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/legal">
              <p className="pop-up-text mv1">Legal</p>
            </Link>
          </li>
        </ul>
      </Popup>
    </>
  );
};

export { Overlay };
export default PopupMenu;
