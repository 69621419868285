import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import "./Projects.scss";
import hex_back from "../../assets/background/hex_ground.png";
import tree_cover from "../../assets/background/tree_cover.png";
import back_cover from "../../assets/IMG_07.jpg";

const palmTree = 2;
const oliveTree = 6;

const stats = [
    {
        name: "Tree funded",
        number: `${palmTree + oliveTree} Trees`
    },
    {
        name: "Farmers supported",
        number: `3`
    },
    {
        name: "CO2 avoided",
        number: `${palmTree * 20 + oliveTree * 11.5} kg/year`
    }
];

const projectData = [
  {
    name: "Sarta",
    about: <p className='pc-text'>Planting 1000 Olive tree in the village of Sarta</p>,
    city: (
      <p className='pc-text'>
        16 km west Salfit city, Sarta is edged by Qarawat Bani Hussan (north),
        Biddya (west), Haris (east) and Bruqin (south). The village has a total
        area of 5,615 dunums , of which 457 dunums are a built up area. Sarta is
        blocked by “Barkan” and “Ariel” settlements and so far the occupation
        confiscated (644) dunums of Sarta lands.
      </p>
    ),
    location: "31°52'22.0\"N 35°29'57.2\"E",
    goal: (
      <p className='pc-text'>
        Helping farmers plant 1,000 olive trees and providing them with all the
        farming tools they need to care for the trees.
      </p>
    ),
    climate_goal: <p className='pc-text'>Approximate CO2 Avoided 1.15 Tons</p>,
  },
  {
    name: "Jericho 1",
    about: (
      <p className='pc-text'>
        By collaborating alongside the Palm Tree Association in Palestine, we
        will facilitate for 250 Palm trees to be adopted in order to support
        Palestinian farmers.
      </p>
    ),
    city: (
      <p className='pc-text'>
        Jericho, which is situated in the Jordan Valley in Palestine , west of
        the Jordan River and north of the Dead Sea, is known for its oasis-like
        surroundings, which are brought about by several natural springs. It was
        a major city in antiquity due to its advantageous placement along old
        trade routes. The name “Jericho” means “Moon City” in the Canaanite
        language.
      </p>
    ),
    location: "31°52'09.1\"N 35°29'47.9\"E",
    goal: (
      <p className='pc-text'>
        Supporting palm trees farmers financially in order to enhance their
        productivity.
      </p>
    ),
    climate_goal: <p className='pc-text'>Maintaining up the 5 tons of CO2 absorption per year</p>,
  },
  {
    name: "Jericho 2",
    about: <p className='pc-text'>Planting 500 Palm Trees in the city of Jericho</p>,
    city: (
      <p className='pc-text'>
        Jericho, which is situated in the Jordan Valley in Palestine , west of
        the Jordan River and north of the Dead Sea, is known for its oasis-like
        surroundings, which are brought about by several natural springs. It was
        a major city in antiquity due to its advantageous placement along old
        trade routes. The name “Jericho” means “Moon City” in the Canaanite
        language.
      </p>
    ),
    location: "31°52'22.0\"N 35°29'57.2\"E",
    goal: (
      <p className='pc-text'>
        Helping farmers plant 500 palm trees and providing them with all the
        farming tools they need to care for the trees.
      </p>
    ),
    climate_goal: <p className='pc-text'>Approximate CO2 Avoided 10 Tons</p>,
  },
];

const parseCoordinates = (coordString) => {
  const parts = coordString.match(/(\d{1,3})°(\d{1,2})'(\d{1,2}\.\d+)"([NSEW])/);
  if (!parts) return null;
  const [_, degrees, minutes, seconds, direction] = parts;
  let decimal = parseFloat(degrees) + parseFloat(minutes) / 60 + parseFloat(seconds) / 3600;
  return direction === 'S' || direction === 'W' ? -decimal : decimal;
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const MapComponent = ({ lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAk7XlRH5Dv7ZlESeBSpEXiQpQBkiTGcyk',
  });

  const options = {
    disableDefaultUI: true, 
    zoomControl: false,     
    mapTypeControl: false,  
    streetViewControl: false, 
    fullscreenControl: false 
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
      zoom={10}
      options={options} 
    >
      <Marker 
        position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
        icon={{
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", 
        }}
      />
    </GoogleMap>
  ) : (
    <div>Loading map...</div>
  );
};

const Projects = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    const handleProjectClick = (index) => {
        setSelectedProject(projectData[index]);
    };

    const handleClosePopup = () => {
        setSelectedProject(null);
    };

    return (
      <div className="projects-root" style={{backgroundImage:`url(${back_cover})`}}>
        <ul className="stats-container">
          {stats.map((stat, index) => (
            <li key={index} className="stats-item">
              <div className="stats-number">{stat.number}</div>
              <div className="stats-name">{stat.name}</div>
            </li>
          ))}
        </ul>

        <ul className="project-list">
          {projectData.map((project, index) => (
            <li
              key={index}
              className="project-item"
              onClick={() => handleProjectClick(index)}
            >
              <div className="project-container">
                <div className="hex">
                  <div
                    className="hex1"
                    
                  >
                    <p className="card-title">{project.name}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {selectedProject && (
          <div className="popup-overlay" onClick={handleClosePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <div className="pop-map">
                <MapComponent
                  lat={parseCoordinates(selectedProject.location.split(' ')[0])}
                  lng={parseCoordinates(selectedProject.location.split(' ')[1])}
                />
              </div>
              <div className="pop-container">
                <p className='pc-title'>{selectedProject.name}</p>
                <div><p className='pc-subtitle'>About the project</p>{selectedProject.about}</div>
                <div><p className='pc-subtitle'>About the area</p>{selectedProject.city}</div>
                <div><p className='pc-subtitle'>Project goal</p>{selectedProject.goal}</div>
                <div><p className='pc-subtitle'>Project climate goal</p>{selectedProject.climate_goal}</div>
                <button className='popup-btn' onClick={handleClosePopup}>Close</button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default Projects;
